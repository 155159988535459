import classes from './Tile.module.css';

import { Box, GridCol, type GridColProps } from '@mantine/core';

export interface TileProps extends GridColProps {}

export function Tile({ children, ...props }: TileProps) {
  return (
    <GridCol {...props}>
      <Box px="xl" py="md" className={classes.root}>
        {children}
      </Box>
    </GridCol>);

}