import classes from './LabeledTile.module.css';

import { ActionIcon, Group, Stack, Text, Tooltip } from '@mantine/core';
import { type Icon, IconInfoCircle } from '@tabler/icons-react';
import type { ReactNode } from 'react';

import { Tile, type TileProps } from './Tile';

export interface LabeledTileProps extends TileProps {
  label: ReactNode;
  tooltip?: ReactNode;
  tooltipIcon?: Icon;
  tooltipIconColor?: string;
  children: ReactNode;
}

export function LabeledTile({
  label,
  tooltip,
  tooltipIcon: Icon = IconInfoCircle,
  tooltipIconColor = 'gray',
  children,
  ...others
}: LabeledTileProps) {
  return (
    <Tile {...others}>
      <Stack h="100%" gap="xs" justify="space-between">
        <Group justify="space-between" align="flex-start" wrap="nowrap" gap="xs">
          <Text component="div" size="sm" lineClamp={1} className={classes.label}>
            {label}
          </Text>

          {tooltip &&
          <Tooltip
            label={tooltip}
            multiline
            withinPortal
            classNames={{
              tooltip: classes.tooltip
            }}>

              <ActionIcon size="xs" variant="subtle" color={tooltipIconColor}>
                <Icon />
              </ActionIcon>
            </Tooltip>}

        </Group>

        {children}
      </Stack>
    </Tile>);

}