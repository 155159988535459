import classes from './StatTile.module.css';

import { Text, rem } from '@mantine/core';
import type { ReactNode } from 'react';

import { LabeledTile, type LabeledTileProps } from './LabeledTile';

interface StatTileProps extends Omit<LabeledTileProps, 'children'> {
  value: ReactNode;
  secondaryValue?: ReactNode;
}

export function StatTile({ value, secondaryValue, ...others }: StatTileProps) {
  return (
    <LabeledTile {...others}>
      <Text component="div" size={rem(28)} ff="var(--observatory-font-family-number)">
        <Text component="span" className={classes.value}>
          {value}
        </Text>
        {secondaryValue && <span className={classes['value-secondary']}>/{secondaryValue}</span>}
      </Text>
    </LabeledTile>);

}