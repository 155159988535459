import classes from './TiledGrid.module.css';

import { Grid, type GridProps } from '@mantine/core';
import cx from 'clsx';

import { LabeledTile, StatTile, Tile } from './Tiles';

type GridPropsWithoutGutter = Omit<
  GridProps,
  'gutter' | 'gutterXs' | 'gutterSm' | 'gutterMd' | 'gutterLg' | 'gutterXl'>;

interface TiledGridProps extends GridPropsWithoutGutter {}

export function TiledGrid({ children, className, ...props }: TiledGridProps) {
  return (
    <Grid gutter={1} className={cx(classes.root, className)} {...props}>
      {children}
    </Grid>);

}

TiledGrid.Tile = Tile;
TiledGrid.LabeledTile = LabeledTile;
TiledGrid.StatTile = StatTile;